.header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #CACACA;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    z-index:100;
    
}

.course{
    background-color: rgb(255, 244, 244);

}

.logo-container{
    width: 10%;
    text-align: left;
    }
.menu-container{
    color: #CACACA;
    width:100%;
    border: 0px solid green;
    height: 100%;
}

.avatar-container{
    width: 5%;
    border: 0px solid red;
}


.dark{
    color: black;
}

.light{
    color:white
}
