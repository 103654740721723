.course-edit-main-container{
display: flex;
flex-direction: row;
width: 100%;
}

.course-edit-lhn-container{
    width: 25%;
    margin-top:70px;
    border: 2px solid gray;
}

.course-edit-lhn-view-section{
    width: 75%;
    
}
.view-content-item{
    width: 100%;
    display: flex;
    flex-direction: row;
}