.course-card{
    display: flex;
    flex-direction: row;
    /* background-color: rgb(193, 233, 193); */
    background-color: #d2f0ed;
    width: 100%;
        height: 40vh;
}

.tile-title{
    display: flex;
    width:40%;
    height: 100%;
    border:0px solid red;
    align-items: center;
    justify-content: center;
}



.tile-code{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:65%;
    height: 90%;
    border-left: 4px solid #333;

    background-color: white;
    /* border-radius: 10px; */
    margin: 1%;

}


  .card-button{
    background-color: #333;
    text-decoration: none;
    color: white;
    /* padding: 10px; */
    width: 70%;
    padding: 5px;
    border-radius:50px;
    align-self: center;
    font-size:medium;
    text-transform: uppercase;
  }

  .card-button:hover{
    background-color: #fafafa;
    color: #333;
    border: 2px solid #333;
    font-weight: bold;
  }

  .code-content{
    height: 70%;
    width: 100%;
    
  }

  .tile-button{
    height: 30%;
    border:0px solid red;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }