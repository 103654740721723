.content-main-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}


.content-card-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:100%;
}

.card-container{
    display: flex;
    margin: 1%;
    justify-content: center;
    width: 100%;
    border-radius: 150px;
}



.card-container:hover{
    border: 1px solid rgb(101, 177, 101);
    cursor: pointer;
}

.content-sub-container{
    display: flex;
    flex-direction: column;
    /* border: 1px solid green; */
    margin: 1%;
    width: 98%;
    background-color: #333;
    border-radius: 20px;
}

.course-section-title{
    padding-top: 5px;
    border-bottom: 0px solid green;
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}