.learning-card{
    display: flex;
    flex-direction: row;
    /* background-color: rgb(193, 233, 193); */
    background-color: #d2f0ed;
    width: 100%;
    height: 40vh;
}

.learning-title{
    display: flex;
    width:40%;
    height: 100%;
    border:0px solid red;
    align-items: center;
    justify-content: center;
}



.learning-code{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width:60%;
    height: 95%;
    border-left: 4px solid rgb(12, 102, 138);
    background-color: white;
    /* border-radius: 10px; */
    margin: 1%;
    

}

.learning-text{
  /* background-color: aqua; */
  text-align: left;
  font-size: medium !important;
  padding: 3%;
  height: 80%;
  text-overflow: ellipsis;
  overflow: hidden;

  
}
.learning-top{
    height: 70%;
}

.learning-footer{
    display: flex;
    justify-content: center;
    /* height: 20%; */
    width:100%;
}
.learning-link{
    background-color: rgb(12, 102, 138);
    text-decoration: none;
    color: white;
    /* padding: 10px; */
    width: 70%;
    padding: 5px;
    border-radius:50px;
    align-self: center;
    font-size:medium;
    text-transform: uppercase;
}

.learning-link:hover{
    background-color: white;
    border:2px solid rgb(12, 102, 138);
    color: rgb(12, 102, 138);
    font-weight: 500;
}

