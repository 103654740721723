.edit-area-main-container{
    display: flex;
    flex-direction: column;
    border: 1px solid orange;
    width: 100%;
    margin-top: 70px;
    /* background-color: aqua; */
}


.edit-area-title{
    display: flex;
    flex-direction: row;
    font-size: 3em;
    justify-content: flex-start;
    border: 0px solid red;
    width: 100%;
    align-items: center;
    /* background-color: rgb(201, 235, 235); */
    padding: 1%;
    
}

.edit-area-sub-container{
    display: flex;
    flex-direction: column;
    width: 100%;

}

.sub-heading{
    display: flex;
    border-bottom: 1px solid #CCCCCC;
    align-self: flex-start;
    width: 70%;
    font-size:2em;
    margin: 0;
    padding: 1%;
}

.topic-content{
    display: flex;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    margin: 0;
    font-size: 1.3em;
}

.item-container{
    background-color: white;
}

.edit-area-sub-topic-container{
    display: flex;
    flex-direction: column;
    border: 3px solid black;
    margin: 1%;
    padding: 1%;
}

/* .edit-content-item{
    display: flex;
    flex-direction: row;
    width: 100%;
} */


.MuiButton-text {
    background-color: green!important;
    color: white!important;
}

input{
    /* background-color: hotpink!important; */
    padding: 4px!important;
    margin: 4px!important;
}