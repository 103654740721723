.blog-card{
    display: flex;
    flex-direction: column;
    /* background-color: rgb(193, 233, 193); */
    background-color: white;
    width: 100%;
    height: 50vh;
    border: 1px solid rgb(206, 206, 206);
    /* border-radius: 20px; */
    box-shadow: 2px 2px 4px 2px rgba(13, 14, 13, 0.5);
}

.blog-image{
    width: 100%;
    height: 60%;
    overflow: hidden;
    padding:1%;
}

.blog-title{
    display: flex;
    justify-content: flex-start;
    text-align: left;
    padding: 5px;
    height: 25%;
    width: 100%;
    border-bottom:1px solid  #BABABA;
    font-size: 0.9em;
    font-weight: 600;

}


.blog-text{
  text-align: left;
  height: 50%;
  padding: 2%;
  overflow: hidden;
  position: relative;
}

.blog-content {
    margin: 2%; /* Remove default margin */
    font-size: 13px!important;/* Remove default padding */
  }


.blog-link{
    background: #333; /* Match the background color */
    /* color: white; */
    font-size: 12px;
    padding: 2%
}

.blog-link a{
    text-decoration: none;
    color:white;
    /* color: black; */
}


