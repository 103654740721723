.menu-app-button {
  display: flex;
  border: 0px solid rgb(124, 196, 124);
  cursor: pointer;
  color: black;
  text-decoration: none;
  align-items: center;
  font-weight: bolder;
  height: 60px;
  margin: 2px;
  justify-content: center;
}

.menu-app-list {
  display: flex;
  cursor: pointer;
  color: black;
  text-decoration: none;
  align-items: center;
  font-weight: bolder;
  margin: 2px;
  width: 150px;
  border-bottom: 1px solid #EAEAEA;
}


.active, .menu-app-button:hover, .menu-app-list:hover {
  /* background-color: brown; */
  /* background-color: darkgrey; */
  /* color:white; */
  /* border-bottom: 4px solid white; */
  background-color: rgb(200, 233, 166);
  box-shadow: wheat;
  border-bottom: 4px solid #028A0F;
  color: black;
}

.menu-app-list:hover {
  /* background-color: brown; */
  /* background-color: darkgrey; */
  /* color:white; */
  /* border-bottom: 4px solid white; */
  background-color: rgb(200, 233, 166);
  box-shadow: wheat;
  border-bottom: 1px solid #028A0F;
  color: black;
}

.course-menu {
  max-height: 80vh;
  overflow: auto;
  margin-left: 10px;
}

.menu-list {
  max-height: 80vh;
}

/* Responsive icon sizes */
.header-icon {
  font-size: 20px;
}

.social-icon {
  font-size: 16px;
  color: #028A0F;
}

.profile-icon {
  font-size: 30px;
  color: #02420d
}

.home {
  color: #028A0F
}


.java {
  color: red
}

.python {
  color: blue
}

.javascript {
  color: orange
}

.clang {
  color: rgb(99, 43, 5)
}

.automation {
  color: rgb(0, 255, 34)
}

.aws {
  color: rgb(0, 84, 117)
}

.react {
  color: rgb(12, 219, 219)
}


.interview {
  color: rgb(162, 0, 255)
}

.progques {
  color: red
}

.twitter {
  color: black
}

.youtube {
  color: red
}

.facebook {
  color: rgb(0, 132, 255)
}

.linkedin {
  color: rgb(38, 0, 255)
}

.cai-logo {
  /* background: aqua; */
  display: flex;

}

.mobile-container {
  display: none;
}

.desktop-container {
  display: flex;
}

.header-menu{
  min-width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border: 1px solid saddlebrown; */
}

.header-menu>a,button{
  /* background-color: sandybrown; */
  margin-left: 15px;
}
.menu-drawer-icon{
  display: none !important;;
  color: #02420d!important;
}

/* Styles for screens with width below 300px */
/* @media screen and (min-width: 200px) and (max-width: 400px) {
  .header-icon {
    font-size: 16px;
  }

  .social-icon {
    font-size: 12px;
    color: #028A0F;
  }

  .profile-icon {
    font-size: 20px;
  }

  .more {
    font-size: 26px;
    margin-right: 15%;
    color: #028A0F
  }


} */

.drawer-menu{
  display: none;
}

.login-button{
  background-color: #02420d;
  color: white;
}

/* Styles for screens with width below 300px */
@media screen and (min-width: 50px) and (max-width: 500px) {
  .menu-drawer-icon{
    display: flex !important;;
    color: #02420d!important;
  }

  .mobile-container {
    display: flex;
  }

  .desktop-container {
    display: none;
  }

  .toolbar-mobile{
    display: flex!important;
    /* background-color: aqua; */
    justify-content: space-between!important;
  }

  .cai-image{
    min-height: 60px;
    min-width: 60px;
    display: flex;
  }
  .login-button{
    display: none;

  }
}

/* Media queries for responsiveness */
/* @media (max-width: 00px) {

  .header-icon {
    font-size: 16px;
  }

  .social-icon {
    font-size: 12px;
    color: #028A0F;
  }

  .profile-icon {
    font-size: 20px;

  }


  .more {
    font-size: 26px;
    margin-right: 15%;
    color: #028A0F
  }

  .mobile-container {
    display: flex;
  }
  
  .desktop-container {
    display: none;
  }
} */


/* @media (max-width: 300px) {
  .cai-logo {
    display: none;
  }

  .mobile-container {
    display: flex;
  }

  .desktop-container {
    display: none;
  }
} */