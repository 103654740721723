.main-container{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    border: 1px solid #cacaca;
    padding: 0px;
}

.site-header{
    background: gray;
}


.site-content{
    background: greenyellow;
}

.site-footer{
    background: hotpink;
}

.dark{
    color: black;
}

.light{
    color:white
}