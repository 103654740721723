.tree-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-left: 20px;
    margin: 1%;
}


@media screen and (min-width: 50px) and (max-width: 505px) {
    .tree-item {
        display: flex;
        flex-direction: row;
        font-size: x-small;
        padding:0px;
        margin: 0%;
    }
    
}