.course-main-container{
display: flex;
flex-direction: row;
/* margin-top: 10px; */
/* background-color: green; */
}

.course-lhn-container{
    width: 20%;
    border-right: 1px solid rgb(145, 206, 145);
    min-height: 90vh;

}

.course-lhn-view-section{
    width: 70%;
    border: 0px solid pink;;
}

.course-index{
    background-color: white;
    width: 15%;
    float: right;
    position: fixed;
    right: 0%;
    opacity: 0.8;

}



@media screen and (min-width: 50px) and (max-width: 505px) {
    .course-lhn-container{
        width: 30%;
        border-right: 1px solid rgb(145, 206, 145);
        /* background-color: aqua; */
        font-size: x-small;
    }
    
    
}