.code-compiler-container {
	width: 100%;
}

.main {
	position: relative;
	
	border: 0px solid red
}

.fullscreen{
	height: calc(102vh - 50px);
}

.notfull{
	height: calc(90vh - 50px);
}

.right{
	display: flex
}

.bottom{
	display:'block'
}

.left-container {
	position: relative;
	height: 100%;
	/* border-right:1px solid rgb(184, 166, 166) */
}

.right-container {
	height: 100%;
	display: flex;
	/* border-right: 1px solid  green; */
	padding: 5px;
}

.left-inline{
	width: 60%;

}

.left-bottom{
	width: 100%;
}

.right-inline{
	width: 40%;
	flex-direction: column;
}


.input-box {
	width:100%;
	height: 50%;
	background-color: grey;
	border: 1px solid #FAFAFA
}

.input-inline, .output-inline{
	position: relative;
	width:100%
}

.input-bottom, .output-bottom{
	position: relative;
	width:50%
}

.output-box {
	display: flex;
	height: 50%;
	overflow-y: auto;
	position: relative;
	justify-content: flex-start;
	align-items: top;
	border: 1px solid gray
}

.input-box textarea {
	font-size: 16px;
}

.spinner-box {
	height: 50%;
	overflow-y: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border:1px solid brown;
}

.spinner-box img {
	width: 200px;
}



.settings{
	position: absolute;
    top: 8px;
    right: 16px;	
	font-size: small;
	color: black;
	background-color: rgb(167, 253, 167);
	border-radius: 5px;
	padding: 0px 5px 0px 5px;
}
.output-box pre {
	font-size: 15px;
	white-space: pre-wrap;
}

.code-inp {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	resize: none;
	padding: 5px;
	color:black
}

.code-inp:focus {
	outline: none;
}

.dark{
	background-color: #3c3c3c;
color: white;
}

.light{
color: black;
}

