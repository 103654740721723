.navbar {
	display: flex;
	align-items: center;
	padding-left: 20px;
	text-align: center;
	color: #afec3f;
	background-color: white;
	justify-content: center;
	/* background-color: #CACACA */
}

.lang-menu{
	display: block !important;
	/* border:1px solid red !important;  */
}

