.view-area-main-container {
    display: flex;
    flex-direction: column;
    border: 0px solid orange;
    width: 100%;
    min-width: 100%;
    margin-top: 10px;
    /* overflow-y: scroll; */
    /* height: 90vh; */
}


.view-area-title {
    display: flex;
    font-size: 3em;
    justify-content: flex-start;
    border: 0px solid red;
    width: 100%;
}

.view-area-sub-container {
    display: flex;
    border: 2px solid green;
    flex-direction: column;
    width: 100%;
    position: relative;
    max-height: 100vh;
    overflow-y: auto;

}

.view-sub-heading {
    display: flex;
    border-bottom: 1px solid #CCCCCC;
    align-self: flex-start;
    width: 90%;
    font-size: 18px;
    margin: 0;
    padding: 1%;
}

.topic-view-content {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    margin: 0;
    font-size: 1.3em;
}

.item-container {
    background-color: white;
}

.topic-index {
    position: absolute;
    top: 10%;
    left: 90%;
}

.edit-content-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #FAFAFA;
    margin: 1%;
    justify-content: center;
    align-items: center;
}

ol li {
    list-style: decimal;
    list-style-position: inside;
}

ul{
    display: list-item;
}

ul li {
    list-style: circle;
    list-style-position: inside;
}

ul ul, ol ol {
    list-style-type: circle; /* Different bullet points for nested lists */
  }
  
  ul ul ul, ol ol ol {
    list-style-type: square; /* Different bullet points for further nested lists */
  }
  
  ul li, ol li {
    margin-bottom: 5px; /* Optional: Add spacing between items */
  }
  
  /* Nested lists indentation */
  ul ul li, ol ol li {
    padding-left: 20px;
  }
  
  ul ul ul li, ol ol ol li {
    padding-left: 40px;
  }
  
  ul ul ul ul li, ol ol ol ol li {
    padding-left: 60px;
  }

@media screen and (min-width: 50px) and (max-width: 505px) {
    .course-index {
        display: none;
    }

    .view-area-sub-container {
        min-width: 120%;
        border: 2px solid green;
        font-size:x-small!important;
    }

    

}