
.footer-container{
    padding: 1%;
    display: flex;
    flex-direction: column;
    /* background:#333; */
    background:linear-gradient(to bottom, green, black);
    width: 98%;
    margin: 1%;
    border-left: 8px solid black;
    color: white;
    position: relative;
    height:40vh;

}

.footer-logo{
    position: absolute;
    left:0%;
    top:15%;
    opacity: 0.5;
    height:90%;
    width: 20%;
    /* border: 1px solid red; */
}

.footer-section{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 2%;
    text-align: left;
    margin-left: 20%;
}

.footer-sub{
    width:15%;
    display:flex;
    flex-direction: column;
    /* border-left: 4px solid black; */
    /* padding-left: 1%; */
    height: 100%;
}

.copyright{
    font-size: small;
    width:100%;
    /* border:1px solid red; */
    text-align: left;
 }

 .footer-subheader{
     font-size: 14px;
     /* border-bottom: 2px solid black; */
 }

 .footer-subitem{
     font-size: 11px;
     padding: 2px;
 }