.learning-main-container{
    display: flex;
    flex-direction: column;
    border: 0px solid yellow;
    width: 100%;
    border-radius: 10px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


.learning-card-container{
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
    justify-content: center;
    width:100%;
    /* background-color: rgb(71, 150, 71); */

}

.learning-container{
    display: flex;
    margin: 1%;
    justify-content: center;
    width: 100%;
}



.learning-container:hover{
    border: 1px solid rgb(101, 177, 101);
    cursor: pointer;
}

.learning-sub-container{
    display: flex;
    flex-direction: column;
    /* border: 1px solid green; */
    margin: 1%;
    width: 98%;
    background-color: rgb(12, 102, 138);
    border-radius: 20px;

    
}

.learning-section-title{
    color: white;
    padding-top: 1%;
    font-size: 1.2em;
    font-weight: 600;
}