.view-area-main-container{
    display: flex;
    flex-direction: column;
    border: 0px solid orange;
    width: 100%;
    /* overflow-y: scroll; */
    /* height: 90vh; */
}


.view-area-title{
    display: flex;
    font-size: 3em;
    justify-content: flex-start;
    border: 0px solid red;
    width: 100%;
}

.view-area-sub-container{
    display: flex;
    border: 0px solid gray;
    flex-direction: column;
    width: 100%;
    position: relative;
    max-height: 100vh;
    /* overflow-y: auto; */

}

.view-sub-heading{
    display: flex;
    border-bottom: 1px solid #CCCCCC;
    align-self: flex-start;
    width: 90%;
    font-size:18px;
    margin: 0;
    padding: 1%;
}

.topic-view-content{
    display: flex;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    margin: 0;
    font-size: 1.3em;
}

.item-container{
    background-color: white;
}

.topic-index{
    position: absolute;
    top: 10%;
    left: 90%;
}

.edit-content-item{
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #FAFAFA;
    margin: 1%;
    justify-content:center;
    align-items: center;
}
