.blog-main-container{
    display: flex;
    flex-direction: column;
    border: 0px solid yellow;
    width: 100%;
}


.blog-card-container{
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
    justify-content: center;
    width:100%;
    /* background-color: rgb(71, 150, 71); */

}

.blog-container{
    display: flex;
    margin: 1%;
    justify-content: center;
    width: 100%;

}



.blog-container:hover{
    border: 0px solid rgb(101, 177, 101);
    /* cursor: pointer; */
    
}

.blog-sub-container{
    display: flex;
    flex-direction: column;
    /* border: 1px solid green; */
    margin: 1%;
    width: 98%;
    /* background-color:rgb(154, 231, 241); */
    border-radius: 20px;
    border: 2px solid #BABABA;

}

.blog-section-title{
    font-size: 1.2em;
    font-weight: 600;
    padding-top: 1%;
}